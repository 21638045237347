import React from "react"
import ImageMeta from "../../components/ImageMeta"
import Layout from "../../components/layout"
import Seamless from "../../components/Seamless"
import { SharedStateProvider } from "../../components/SharedState/PageSharedState"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import CTA from "../../components/CTA/CTA"
import SEO from "../../components/seo"
import TextPhoto from "../../components/TextPhoto/TextPhoto"
import { graphql } from "gatsby"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link } from "gatsby"
import { instructions } from "../../cms/collections/instructions"

var classNames = require("classnames")

function OurReviews(props) {
  function hasBoth() {
    if (
      props.post.hearWhatTheyHaveToSay.videos.patients.youtube &&
      props.post.hearWhatTheyHaveToSay.videos.referringProviders.youtube
    ) {
      return true
    } else {
      return false
    }
  }

  function hasOnlyOne() {
    if (!hasBoth()) {
      if (
        props.post.hearWhatTheyHaveToSay.videos.patients.youtube ||
        props.post.hearWhatTheyHaveToSay.videos.referringProviders.youtube
      ) {
        return true
      }
    } else {
      return false
    }
  }

  let columnsClasses = classNames("columns", {
    "has-text-centered": hasOnlyOne()
  })
  let blockClasses = classNames("columns patient-block", {
    "is-centered": hasOnlyOne()
  })
  let columnWidth = classNames("column", {
    "is-9": hasOnlyOne()
  })

  if (
    props.post.hearWhatTheyHaveToSay.videos.patients.youtube ||
    props.post.hearWhatTheyHaveToSay.videos.referringProviders.youtube
  ) {
    return (
      <div
        className={`body-section reviews-block ${
          props.layout ? "location-section" : ""
        }`}
      >
        <div className={columnsClasses}>
          <div className="column is-4"></div>
          <div className="column" style={{ marginBottom: "2.25rem" }}>
            <h3 className="has-text-centered-tablet">
              {props.post.hearWhatTheyHaveToSay.heading}
            </h3>
          </div>
          <div className="column is-4"></div>
        </div>

        <div className={`is-centered ${blockClasses}`}>
          <div className="column is-2"></div>
          {props.post.hearWhatTheyHaveToSay.videos.patients.youtube &&
            props.post.hearWhatTheyHaveToSay.videos.patients.imageId && (
              <div className={`${columnWidth} patients-and-referrals`}>
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${props.post.hearWhatTheyHaveToSay.videos.patients.youtube}`}
                  hasWatchVideo={false}
                  controls={true}
                  playing={true}
                  language={props.language}
                >
                  <ImageMeta
                    cloudName="nuvolum"
                    className="hoverable"
                    responsiveUseBreakpoints
                    responsive
                    publicId={
                      props.post.hearWhatTheyHaveToSay.videos.patients.imageId
                    }
                    version="1580777124"
                    width="auto"
                  ></ImageMeta>
                </TheaterVideo>
                <div className="review-block-caption">
                  <h5 className="image-caption">
                    <TheaterVideo
                      videoUrl={`https://www.youtube.com/watch?v=${props.post.hearWhatTheyHaveToSay.videos.patients.youtube}`}
                      hasWatchVideo={false}
                      controls={true}
                      playing={true}
                      language={props.language}
                    >
                      <a>
                        {
                          props.post.hearWhatTheyHaveToSay.videos.patients
                            .caption
                        }
                      </a>
                    </TheaterVideo>
                  </h5>
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${props.post.hearWhatTheyHaveToSay.videos.patients.youtube}`}
                    buttonText={"Watch Video"}
                    textOnly
                    controls={true}
                    playing={true}
                    onlyButton={true}
                    language={props.language}
                    containerClass="review-block-theater"
                  />
                </div>
              </div>
            )}
          {hasBoth() && <div className="column is-2"></div>}
          {props.post.hearWhatTheyHaveToSay.videos.referringProviders.youtube &&
            props.post.hearWhatTheyHaveToSay.videos.referringProviders
              .imageId && (
              <div className={`${columnWidth} patients-and-referrals`}>
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${props.post.hearWhatTheyHaveToSay.videos.referringProviders.youtube}`}
                  hasWatchVideo={false}
                  controls={true}
                  playing={true}
                  language={props.language}
                >
                  <ImageMeta
                    cloudName="nuvolum"
                    className="hoverable"
                    publicId={
                      props.post.hearWhatTheyHaveToSay.videos.referringProviders
                        .imageId
                    }
                    width="auto"
                  ></ImageMeta>
                </TheaterVideo>
                <div className="review-block-caption">
                  <h5 className="image-caption">
                    <TheaterVideo
                      videoUrl={`https://www.youtube.com/watch?v=${props.post.hearWhatTheyHaveToSay.videos.referringProviders.youtube}`}
                      hasWatchVideo={false}
                      controls={true}
                      playing={true}
                      language={props.language}
                    >
                      <a>
                        {
                          props.post.hearWhatTheyHaveToSay.videos
                            .referringProviders.caption
                        }
                      </a>
                    </TheaterVideo>
                  </h5>
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${props.post.hearWhatTheyHaveToSay.videos.referringProviders.youtube}`}
                    buttonText={"Watch Video"}
                    textOnly
                    controls={true}
                    playing={true}
                    onlyButton={true}
                    language={props.language}
                    containerClass="review-block-theater"
                  />
                </div>
              </div>
            )}
          <div className="column is-2"></div>
        </div>
      </div>
    )
  } else {
    return ""
  }
}

export default OurReviews
